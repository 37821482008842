import { GitHub, MicRounded, Twitter } from "@material-ui/icons"
import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  description,
  github,
  icon,
  links,
  name,
  speakerdeck,
  twitter,
} from "./bio.module.sass"

const Bio = ({ isAbout }) => (
  <StaticQuery
    query={bioQuery}
    render={(data) => {
      return (
        <>
          <GatsbyImage
            image={data.avatar.childImageSharp.gatsbyImageData}
            alt="キクナントカ"
            className={icon}
          />
          <p className={name}>キクナントカ</p>
          <p className={description}>
            ソフトウェアエンジニアをしています。Flutter、Rails、GatsbyJSを主に触っています。趣味でボードゲームを制作したり、個人開発したりしています。
            {!isAbout && (
              <span>
                詳しくは<Link to="/about">こちら</Link>。
              </span>
            )}
          </p>
          <div className={links}>
            <a
              href={`https://twitter.com/kikunantoka`}
              target="_blank"
              rel="noopener noreferrer"
              className={twitter}
            >
              <Twitter />
            </a>
            <a
              href={`https://github.com/kikunantoka`}
              target="_blank"
              rel="noopener noreferrer"
              className={github}
            >
              <GitHub />
            </a>
            <a
              href={`https://speakerdeck.com/kikunantoka`}
              target="_blank"
              rel="noopener noreferrer"
              className={speakerdeck}
            >
              <MicRounded />
            </a>
          </div>
        </>
      )
    }}
  />
)

const bioQuery = graphql`
  query BioQuery {
    avatar: file(relativePath: { eq: "profile-icon.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`

export default Bio

import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { backgroundStyle, filter } from "./section.module.sass"

const Section = ({
  backgroundImage,
  backgroundColor,
  background,
  children,
  noPaddingTop,
  noPaddingBottom,
  paddingSize = "48px",
  translucenceBlack,
  round,
  fluid,
}) => {
  let style = {
    backgroundImage: backgroundImage ? "url(" + backgroundImage + ")" : "none",
    backgroundColor: backgroundColor ? backgroundColor : "transparent",
    borderRadius: round ? "8px" : "0",
  }
  if (background) {
    style.background = background
  }
  return (
    <div style={style} className={backgroundStyle}>
      {(() => {
        if (fluid) {
          return (
            <GatsbyImage
              image={fluid}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
              }}
            />
          )
        }
      })()}
      <div
        className={filter}
        style={{
          background:
            backgroundImage || translucenceBlack ? "rgba(0,0,0,0.2)" : "none",
          paddingTop: noPaddingTop ? "0" : paddingSize,
          paddingBottom: noPaddingBottom ? "0" : paddingSize,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
